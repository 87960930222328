import api from './api'
import jwt_decode from 'jwt-decode'
import apiEndpoints from '@/constants/api/apiEndpoints'

export default {
  isAuthenticated() {
    return !!this.getToken()
  },
  getRolesFromToken() {
    let decodedToken = this.getDecodedToken()
    return (decodedToken != null && decodedToken['roles']) || []
  },
  getDecodedToken() {
    const token = this.getToken()
    if (token) {
      return jwt_decode(token)
    }
    return null
  },
  getUser() {
    const user = window.localStorage.getItem('vers-user')
    return user ? JSON.parse(user) : {}
  },
  getToken() {
    return this.getUser().token
  },
  async login(state, { userName, password }) {
    const userResponse = await api()
      .post(`${apiEndpoints.auth}/signin`, {
        userName,
        password,
      })
      .catch((err) => console.error(err))

    if (userResponse) {
      window.localStorage.setItem(
        'vers-user',
        JSON.stringify(userResponse.data)
      )
      state.logoutReason = undefined
      state.authenticated = true
      state.roles = userResponse.data.roles
      return true
    }
    return false
  },
  logout(state, logoutReason) {
    state.authenticated = false
    state.logoutReason = logoutReason
    state.order.order = null
    state.order.orderResults = []
    state.roles = []
    window.localStorage.removeItem('vers-user')
  },
  async getSideId() {
    return await api()
      .get(`${apiEndpoints.auth}/siteId`)
      .catch((err) => err)
  },
}
